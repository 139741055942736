body {
}
.slick-slider {
    overflow: hidden;
}
* {
    -webkit-focus-ring-color: none;
    outline: none;
}

@font-face {
    font-family: "Myriad";
    src: url("./fonts/Myriad-Pro-Black-Condensed.otf");
  }
  