.button {
  background-color: #e7e7e7; /* Green */
  border: none;
  border-radius: 20px;
  background: linear-gradient(91.59deg, #82c413 4.06%, #a5f126 103.33%);
  color: white;
  box-shadow: 1px 1px 4px rgba(141, 141, 141, 0.25);
  border-radius: 20px;
  padding: 0.6em 0em;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 1rem;
  margin: 5px auto;
  font-weight: bold;
  width: 15em;
  height: 46px;
  margin-bottom: 20px;
  cursor: pointer;
}

/* bottom of the page */
.button {
  background-color: #e7e7e7; /* Green */
  border: none;
  border-radius: 20px;
  background: linear-gradient(91.59deg, #82c413 4.06%, #a5f126 103.33%);
  color: white;
  box-shadow: 1px 1px 4px rgba(141, 141, 141, 0.25);
  border-radius: 20px;
  padding: 0.6em 0em;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 1rem;
  margin: 5px auto;
  font-weight: bold;
  width: 15em;
  height: 46px;
  margin-bottom: 20px;
  cursor: pointer;
}

.button-wrapper {
  height: 150px;
}

.btn-style {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.modal-btn {
  border: none;
  border-radius: 20px;
  box-shadow: 1px 1px 4px rgba(141, 141, 141, 0.25);
  border-radius: 20px;
  padding: 0.8em 1em;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 16px;
  margin: 5px auto;
  font-weight: bold;
  width: 15em;
  height: 40px;
  width: 8em;
}

.modal-btn-share {
  font-weight: 500;
  font-size: 13px;
  line-height: 12px;
  color: rgba(121, 192, 0, 1);
  background-color: white;
}

.modal-btn-edit {
  font-weight: 500;
  font-size: 13px;
  line-height: 12px;
  background-color: rgba(121, 192, 0, 1);
  border: 1px solid #ffffff;
  color: white;
}

.modal-btn-logo {
  margin-right: 10px;
}

.login-btn {
  margin-top: 3em;
}

.signup-btn,
.email-recover-btn-active,
.email-recover-btn-inactive {
  margin-top: 3em;
  margin-bottom: 2em;
  background: linear-gradient(92.15deg, #353d49 0%, #223a60 102.76%);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  font-size: 0.9em;
  font-weight: 500;
  text-decoration: none;
}

.email-recover-btn-active {
  margin-top: 1em;
}

.email-recover-btn-inactive {
  background: #e7e7e7;
  color: rgba(194, 194, 194, 1);
  margin-top: 1em;
}

.signup-btn:hover {
  color: white;
  text-decoration: none;
}

.button-wrapper {
  height: 150px;
}

.button.button_disabled {
  background: #e7e7e7;
  color: rgba(194, 194, 194, 1);
}
